import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "./pages/home/Home";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import useWindowWidth from "./hooks/useWindowWidth";

function App() {
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (windowWidth > 1024) {
      const videos = [
        "/assets/portfolio/baraka.webm",
        "/assets/portfolio/birqadam.webm",
        "/assets/portfolio/orginfo.mp4",
        "/assets/header/header.webm",
      ]; // Preload qilinadigan videolar

      const promises = videos.map((src) => {
        return new Promise((resolve, reject) => {
          const video = document.createElement("video");
          video.preload = "metadata"; // Preloading bilan ishlash
          video.src = src;
          video.oncanplaythrough = resolve; // Preload tugallanganda tetiklanadi
          video.onerror = reject; // Xatolarni qayta ishlash
        });
      });

      Promise.all(promises)
        .then(() => setLoading(false))
        .catch((error) => {
          console.error("Video preloading failed:", error);
          setLoading(false);
        });
    } else {
      setLoading(false); // Mobil qurilmalarda videolarni yuklamaslik
    }
  }, [windowWidth]);

  if (loading) {
    return <div className="preloader-loading"></div>;
  }

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
      <ToastContainer />
    </>
  );
}

export default App;
