import { useEffect, useRef } from "react";
import { useInView, useMotionValue, useSpring } from "framer-motion";

import "./aboutUs.scss";

function Counter({ value, direction = "up", className, suffix }) {
  const ref = useRef(null);
  const motionValue = useMotionValue(direction === "down" ? value : 0);
  const springValue = useSpring(motionValue, {
    damping: 100,
    stiffness: 100,
  });
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  useEffect(() => {
    if (isInView) {
      motionValue.set(direction === "down" ? 0 : value);
    }
  }, [motionValue, isInView, direction, value]);

  useEffect(
    () =>
      springValue.on("change", (latest) => {
        if (ref.current) {
          ref.current.textContent = `${suffix}${Intl.NumberFormat("en-US").format(
            latest.toFixed(0),
          )}`;
        }
      }),
    [springValue, suffix],
  );

  return (
    <span className={className} ref={ref}>
      {suffix}0
    </span>
  );
}

function AboutUs() {
  const counters = [
    { end: 24, suffix: "+", label: "Сотрудников" },
    { end: 16, suffix: "+", label: "Успешных проектов" },
    { end: 12, suffix: "", label: "Партнеров" },
    { end: 5, suffix: "+", label: "Год на рынке" },
  ];

  return (
    <div className="about-us-component">
      <div className="content-right">
        {counters.map((counter, index) => (
          <div className="box" key={index}>
            <Counter suffix={counter.suffix} className="number" value={counter.end} />
            <p>{counter.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutUs;
