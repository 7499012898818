import { Swiper, SwiperSlide } from "swiper/react";
import { AnimatedText } from "../../components/animated-text";
import { motion } from "framer-motion";
import { useState } from "react";

import "./news.scss";
import "swiper/css";

import img1 from "../../assets/vlog/img1.webp";
import img2 from "../../assets/vlog/img2.webp";
import img3 from "../../assets/vlog/img3.webp";
import img4 from "../../assets/vlog/img4.webp";

const slidesData = [
  { imageSrc: img1, title: "Бегуны компании участвовали на марафоне в Самарканде" },
  { imageSrc: img2, title: "Team-building в ледовом комплексе" },
  { imageSrc: img3, title: "Научились играть в гольф всей командой" },
  { imageSrc: img4, title: "Стрелялки против выгорания" },
];

const ImageCard = ({ imageSrc, title }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <motion.div
      className="box"
      whileHover={{
        scale: 1.07, // Hoverda zoom effekt
        transition: { duration: 0.1 },
      }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div className="linear" />
      <motion.img
        src={imageSrc}
        alt={title}
        animate={{
          scale: isHovered ? 1 : 1.2, // Image uchun qo‘shimcha zoom
          transition: { duration: 0.5, ease: "easeInOut" },
        }}
        loading="lazy"
      />
      <motion.div className="text">{title}</motion.div>
    </motion.div>
  );
};

function News(props) {
  return (
    <div id="vlog" className="news-component">
      <div className="main-title">
        <div className="line" />
        <div className="text">
          <AnimatedText text="Влог" />
        </div>
      </div>
      <Swiper
        slidesPerView={3.2}
        centeredSlides={false}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[]}
        lazy={true}
        className="content-box"
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            centeredSlides: false,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 25,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 3.2,
            spaceBetween: 30,
            centeredSlides: false,
          },
        }}
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <ImageCard imageSrc={slide.imageSrc} title={slide.title} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default News;
