import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import useWindowWidth from "../../hooks/useWindowWidth";
import { AnimatedElement, AnimatedText } from "../../components/animated-text";

import "swiper/css";
import "./ourTeam.scss";

import munira from "../../assets/team/img1.jpg";
import ismoil from "../../assets/team/img2.png";
import murodjon from "../../assets/team/img3.png";
import nodira from "../../assets/team/img4.png";
import azizbek from "../../assets/team/img5.png";
import oybek from "../../assets/team/img6.png";
import hindol from "../../assets/team/img7.png";
import jahongir from "../../assets/team/img8.png";
import ibroxim from "../../assets/team/img9.png";
import bahrom from "../../assets/team/img10.png";
import shohjahon from "../../assets/team/img11.png";
import asadbek from "../../assets/team/img12.png";

function OurTeam(props) {
  const windowWidth = useWindowWidth();

  return (
    <div id="team" className="our-team-component">
      <div className="main-title">
        <div className="line" />
        <div className="text">
          <AnimatedText text="Команда" />
        </div>
      </div>

      {windowWidth > 1236 ? (
        <div>
          <div className="content-box content-box1">
            <AnimatedElement delay={0.7} threshold={0.7}>
              <div className="box box1">
                <div className="box-img">
                  <img loading="lazy" src={munira} alt="Munira" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Мунира</div>
                    <div className="role">Менеджер</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement delay={0.4} threshold={0.7}>
              <div className="box box2">
                <div className="box-img">
                  <img loading="lazy" src={ismoil} alt="Исмоил" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Исмоил</div>
                    <div className="role">PM</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement threshold={0.7}>
              <div className="box box3">
                <div className="box-img">
                  <img loading="lazy" src={murodjon} alt="Муроджон" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Муроджон</div>
                    <div className="role">CEO</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement delay={0.4} threshold={0.7}>
              <div className="box box4">
                <div className="box-img">
                  <img loading="lazy" src={nodira} alt="Нодира" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Нодира</div>
                    <div className="role">UX / UI design</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
          </div>
          <div className="content-box content-box2">
            <AnimatedElement delay={0.7} threshold={0.7}>
              <div className="box box1">
                <div className="box-img">
                  <img loading="lazy" src={azizbek} alt="Азизбек" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Азизбек</div>
                    <div className="role">Mobile dev</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement delay={0.4} threshold={0.7}>
              <div className="box box2">
                <div className="box-img">
                  <img loading="lazy" src={oybek} alt="Ойбек" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Ойбек</div>
                    <div className="role">Back-end</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement threshold={0.7}>
              <div className="box box3">
                <div className="box-img">
                  <img loading="lazy" src={hindol} alt="Хиндол" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Хиндол</div>
                    <div className="role">PM</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement delay={0.4} threshold={0.7}>
              <div className="box box4">
                <div className="box-img">
                  <img loading="lazy" src={jahongir} alt="Жахонгир" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Жахонгир</div>
                    <div className="role">Front-end</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
          </div>
          <div className="content-box content-box3">
            <AnimatedElement delay={0.7} threshold={0.7}>
              <div className="box box1">
                <div className="box-img">
                  <img loading="lazy" src={ibroxim} alt="Иброхим" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Иброхим</div>
                    <div className="role">Back-end</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement delay={0.4} threshold={0.7}>
              <div className="box box2">
                <div className="box-img">
                  <img loading="lazy" src={bahrom} alt="Бахром" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Бахром</div>
                    <div className="role">Front-end</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement threshold={0.7}>
              <div className="box box3">
                <div className="box-img">
                  <img loading="lazy" src={shohjahon} alt="Шохжахон" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Шохжахон</div>
                    <div className="role">Back-end</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement delay={0.4} threshold={0.7}>
              <div className="box box4">
                <div className="box-img">
                  <img loading="lazy" src={asadbek} alt="Асадбек" />
                </div>
                <div className="box-overlay-container">
                  <div className="box-overlay">
                    <div className="name">Асадбек</div>
                    <div className="role">Mobile dev</div>
                  </div>
                </div>
              </div>
            </AnimatedElement>
          </div>
        </div>
      ) : (
        <div className="team-group">
          <Swiper
            slidesPerView={4}
            spaceBetween={32}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="content-swiper"
            breakpoints={{
              320: {
                slidesPerView: 2.2,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 24,
              },
            }}
          >
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={murodjon} alt="Муроджон" />
                <div className="team-member-title">
                  <div className="name">Муроджон</div>
                  <div className="role">CEO</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={munira} alt="Munira" />
                <div className="team-member-title">
                  <div className="name">Мунира</div>
                  <div className="role">Менеджер</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={ismoil} alt="Исмоил" />
                <div className="team-member-title">
                  <div className="name">Исмоил</div>
                  <div className="role">PM</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={hindol} alt="Хиндол" />
                <div className="team-member-title">
                  <div className="name">Хиндол</div>
                  <div className="role">PM</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={azizbek} alt="Азизбек" />
                <div className="team-member-title">
                  <div className="name">Азизбек</div>
                  <div className="role">Mobile dev</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={jahongir} alt="Жахонгир" />
                <div className="team-member-title">
                  <div className="name">Жахонгир</div>
                  <div className="role">Front-end</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <Swiper
            slidesPerView={4}
            spaceBetween={32}
            loop={true}
            modules={[Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            className="content-swiper"
            breakpoints={{
              320: {
                slidesPerView: 2.2,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 24,
              },
            }}
          >
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={nodira} alt="Нодира" />
                <div className="team-member-title">
                  <div className="name">Нодира</div>
                  <div className="role">UX / UI design</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={oybek} alt="Ойбек" />
                <div className="team-member-title">
                  <div className="name">Ойбек</div>
                  <div className="role">Back-end</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={bahrom} alt="Бахром" />
                <div className="team-member-title">
                  <div className="name">Бахром</div>
                  <div className="role">Front-end</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={shohjahon} alt="Шохжахон" />
                <div className="team-member-title">
                  <div className="name">Шохжахон</div>
                  <div className="role">Back-end</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={ibroxim} alt="Иброхим" />
                <div className="team-member-title">
                  <div className="name">Иброхим</div>
                  <div className="role">Back-end</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team-member">
                <img loading="lazy" src={asadbek} alt="Асадбек" />
                <div className="team-member-title">
                  <div className="name">Асадбек</div>
                  <div className="role">Mobile dev</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      )}
    </div>
  );
}

export default OurTeam;
