import React, { useEffect, useState } from "react";

import HamburgerIcon from "../hamburger-icon/HamburgerIcon";
import "./mobile_navbar.scss";

// const LANGUAGES = [
//   {
//     code: "ru",
//     label: "РУС",
//   },
//   {
//     code: "uz",
//     label: "UZ",
//   },
//   {
//     code: "en",
//     label: "ENG",
//   },
// ];

const MobileNavbar = ({ setIsModalOpen }) => {
  // const { t } = useTranslation();
  // const { pathname } = useLocation();

  const [menu_show, setMenuShow] = useState(false);

  const toggleMenu = (event) => {
    setMenuShow(!menu_show);
    document.body.style.overflow = !menu_show ? "hidden" : "auto"; // scrollni boshqarish
  };
  const toggleMenuForOpenMenu = (event) => {
    event.preventDefault();
    setMenuShow(!menu_show);
    document.body.style.overflow = !menu_show ? "hidden" : "auto"; // scrollni boshqarish
  };

  // const [isOpen, setIsOpen] = useState(false);
  // const [offset, setOffset] = useState(135);
  // const [screenSize, setScreenSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  // useEffect(() => {
  //   const handleResize = () => {
  //     setScreenSize({
  //       width: Number(window.innerWidth),
  //       height: Number(window.innerHeight),
  //     });
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (screenSize.width > 1440) {
  //     setOffset(-85);
  //   } else if (screenSize.width < 1440 && screenSize.width > 576) {
  //     setOffset(-85);
  //   } else {
  //     setOffset(-100);
  //   }
  // }, [screenSize]);

  // const [lang_open, setLangOpen] = useState(false);

  // const { i18n } = useTranslation();

  // const [currentLang, setCurrentLang] = useState(i18n.language);

  // const changeLanguage = (lang = "en") => {
  //   setCurrentLang(lang);
  //   setLangOpen(false);
  //   i18n.changeLanguage(lang);
  // };

  // const ref = useRef(null);

  // const handleClickOutside = (event) => {
  //   if (lang_open === true) {
  //     // Agar tillar qismini ochuvchi tugmani bosgan bo'lsa, hech narsa qilmaslik kerak
  //     if (
  //       ref.current &&
  //       !ref.current.contains(event.target) &&
  //       event.target.id !== "language-button"
  //     ) {
  //       setLangOpen(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // });

  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="mobile-navbar">
      <nav className="mobile-navbar-component">
        <div className="mobile-navbar">
          <div className="content-box">
            <a href="tel:+998948147777">
              <button
                // onClick={() => setIsModalOpen(true)}
                type="button"
                className="header-button w-100 text-flip"
              >
                <div class="text-flip-content vertical">
                  <div class="text">Стать клиентом</div>
                  <div class="hover-text">Стать клиентом</div>
                </div>
              </button>
            </a>
            <div className="d-flex gap-2 w-100">
              <a className="d-block w-100" href="#portfolio">
                <button className="jobs-button w-100 text-flip">
                  <div class="text-flip-content vertical">
                    <div class="text">Кейсы</div>
                    <div class="hover-text">Кейсы</div>
                  </div>
                </button>
              </a>
              <button onClick={toggleMenuForOpenMenu} className="jobs-button icon-button">
                <HamburgerIcon menu_show={menu_show} />
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className={`left-menu  ${menu_show ? "open" : "close"}`}>
        <ul>
          <li onClick={toggleMenu}>
            <a href="#about_us">
              <div className="text-flip">
                <div class="text-flip-content vertical">
                  <div class="text">Почему мы?</div>
                  <div class="hover-text">Почему мы?</div>
                </div>
              </div>
            </a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#portfolio">
              <div className="text-flip">
                <div class="text-flip-content vertical">
                  <div class="text">Портфолио</div>
                  <div class="hover-text">Портфолио</div>
                </div>
              </div>
            </a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#services">
              <div className="text-flip">
                <div class="text-flip-content vertical">
                  <div class="text">Услуги</div>
                  <div class="hover-text">Услуги</div>
                </div>
              </div>
            </a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#team">
              <div className="text-flip">
                <div class="text-flip-content vertical">
                  <div class="text">Команда</div>
                  <div class="hover-text">Команда</div>
                </div>
              </div>
            </a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#vlog">
              <div className="text-flip">
                <div class="text-flip-content vertical">
                  <div class="text">Влог</div>
                  <div class="hover-text">Влог</div>
                </div>
              </div>
            </a>
          </li>
          <a href="tel:+998948147777">
            <button
              className="text-flip"
              // onClick={() => {
              //   toggleMenu();
              //   setIsModalOpen(true);
              // }}
            >
              <div class="text-flip-content vertical">
                <div class="text">Заказать проект</div>
                <div class="hover-text">Заказать проект</div>
              </div>
            </button>
          </a>
          <div className="close" onClick={toggleMenuForOpenMenu}>
            <HamburgerIcon white="white" menu_show={menu_show} />
          </div>
        </ul>
      </div>
    </div>
  );
};

export default MobileNavbar;
