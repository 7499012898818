import { AnimatedElement, AnimatedText } from "../../components/animated-text";
import "./ourAdvantages.scss";

function OurAdvantages(props) {
  return (
    <div id="about_us" className="our-advantages-component">
      <div className="main-title">
        <div className="line" />
        <div className="text">
          <AnimatedText text="ПОЧЕМУ МЫ?" />
        </div>
      </div>

      <div className="group-elements">
        <AnimatedElement>
          <div className="content">
            <div className="content-info">
              <div className="content-title">Современные решения</div>
              <div className="content-description">
                Предлагаем инновационные решения, которые помогают автоматизировать процессы и
                повышать эффективность бизнеса.
              </div>
            </div>
            <div className="content-number">01</div>
          </div>
        </AnimatedElement>
        <AnimatedElement>
          <div className="content">
            <div className="content-info">
              <div className="content-title">Индивидуальный подход</div>
              <div className="content-description">
                Предоставляем кастомизированные продукты и услуги, ориентированные на потребности
                каждого клиента.
              </div>
            </div>
            <div className="content-number">02</div>
          </div>
        </AnimatedElement>
        <AnimatedElement>
          <div className="content">
            <div className="content-info">
              <div className="content-title">Поддержка и сопровождение</div>
              <div className="content-description">
                Мы обеспечиваем техническую поддержку, регулярные обновления и мониторинг для
                обеспечения стабильности работы.
              </div>
            </div>
            <div className="content-number">03</div>
          </div>
        </AnimatedElement>
        <AnimatedElement>
          <div className="content">
            <div className="content-info">
              <div className="content-title">Гибкость и адаптивность решений</div>
              <div className="content-description">
                Предлагаем гибкие решения, которые можно адаптировать к изменяющимся бизнес-условиям
                и требованиям клиентов.
              </div>
            </div>
            <div className="content-number">04</div>
          </div>
        </AnimatedElement>
      </div>
    </div>
  );
}

export default OurAdvantages;
