import { motion, useAnimation, useInView as useInViewFremer } from "framer-motion";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

const defaultAnimations = {
  hidden: {
    opacity: 0,
    y: 20,
    filter: "blur(12px)",
  },
  visible: {
    opacity: 1,
    filter: "blur(0px)",
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
};

export const AnimatedText = ({
  text,
  el: Wrapper = "p",
  className = "",
  once = false,
  amount = 0.5,
  threshold = 0.2,
  repeatDelay,
  animation = defaultAnimations,
}) => {
  const controls = useAnimation();
  const textArray = Array.isArray(text) ? text : [text];
  const ref = useRef(null);
  const isInView = useInViewFremer(ref, { amount: amount, threshold: threshold, once });

  useEffect(() => {
    let timeout;
    const show = () => {
      controls.start("visible");
      if (repeatDelay) {
        timeout = setTimeout(async () => {
          await controls.start("hidden");
          controls.start("visible");
        }, repeatDelay);
      }
    };

    if (isInView) {
      show();
    } else {
      controls.start("hidden");
    }

    return () => clearTimeout(timeout);
  }, [controls, isInView, repeatDelay]);

  return (
    <Wrapper style={{ margin: 0 }} className={className}>
      <motion.span
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { transition: { staggerChildren: 0.1 } },
          hidden: {},
        }}
        aria-hidden
      >
        {textArray.map((line, lineIndex) => (
          <span className="d-block" key={`${line}-${lineIndex}`}>
            {line.split(" ").map((word, wordIndex) => (
              <span className="d-inline-block" key={`${word}-${wordIndex}`}>
                {word.split("").map((char, charIndex) => (
                  <motion.span
                    key={`${char}-${charIndex}`}
                    className="d-inline-block"
                    variants={animation}
                  >
                    {char}
                  </motion.span>
                ))}
                <span className="d-inline-block">&nbsp;</span>
              </span>
            ))}
          </span>
        ))}
      </motion.span>
    </Wrapper>
  );
};

export function AnimatedTextElement({ text, delay = 0 }) {
  const [ref, inView] = useInView({
    triggerOnce: true, // Animatsiya faqat bir marta ishlashini ta'minlaydi
    threshold: 0.2, // Elementning 20% ko'ringanda trigger ishlaydi
  });
  return (
    <div ref={ref} style={{ display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Initial state (word starts off-screen and invisible)
        animate={inView ? { opacity: 1, y: 0 } : {}} // Animate to opacity: 1 and position x: 0 (on-screen)
        transition={{
          delay: 0.2 + delay, // Delay between words
          duration: 0.5, // Animation duration
          type: "spring", // Use a spring animation for a smooth effect
          stiffness: 100, // Control spring stiffness
        }}
      >
        {text}
      </motion.div>
    </div>
  );
}

export function AnimatedElement({ children, delay = 0, threshold = 0.2 }) {
  const [ref, inView] = useInView({
    triggerOnce: true, // Animatsiya faqat bir marta ishlashini ta'minlaydi
    threshold: threshold, // Elementning 20% ko'ringanda trigger ishlaydi
  });

  return (
    <div
      ref={ref} // useInView hook'idan olingan ref
      style={{
        display: "flex",
        justifyContent: "start",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Animatsiyaning boshlang'ich holati
        animate={inView ? { opacity: 1, y: 0 } : {}} // inView = true bo'lganda animatsiya ishlaydi
        transition={{
          delay: 0.2 + delay, // Har bir element uchun kechikish
          duration: 0.5, // Animatsiya davomiyligi
          type: "spring", // Spring animatsiyasi
          stiffness: 100, // Spring qattiqligi
        }}
        style={{
          width: "100%",
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}
